/* Author Section Styles */
.aboutAuthor img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
}

.aboutAuthor {
    text-align: center;
    padding-left: 0px;
    font-size: 18px;
    font-weight: bold;
    list-style-type: none;
}

.aboutAuthor .padding-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
}

.aboutAuthor .center {
    text-align: center!important;
}

.aboutAuthor .third {
    float:left;
    width: 100%;
}

.aboutAuthor .twothird {
    float:left;
    width: 100%;
}

@media (min-width: 601px) {
    .aboutAuthor .third {
        width: 33.33333%;
    }
    
    .aboutAuthor .twothird {
        width: 66.66666%;
    }

    .aboutAuthor {
        text-align: right;
    }
}