/* Blog Styles */
.blogpost {
    margin: auto;
    max-width:980px;
    padding: 16px;
}

.blogpost .title {
    font-weight: bold;
    font-size: 40px;
    text-align: center;
}
  
.blogpost .date {
    font-size: 16px;
    color: #757575;
    text-align: right;
}
  
.blogpost h2 {
    font-size: 30px;
    font-weight: bold;
    padding: 16px;
    padding-bottom: 0px;
    width: 100%
}
  
.blogpost h3 {
    font-size: 20px;
    font-weight: bold;
    padding: 16px;
    width: 100%
}

.blogpost ul {

}

.blogpost li {
    padding: 4px;
    font-size: 18px;
}
  
.blogpost .body {
    font-size: 18px;
    padding: 16px;
}

@media (max-width: 601px) {
    .blogpost .body {
        padding: 8px;
    }
    
}
  
.blogpost .code {
    margin-left: 16px;
    text-align: left;
    padding:16px;
    background-color: #DDDDDD;
    overflow-x: scroll;
    font-family: monospace;
}
  
.blogpost .quote {
    padding: 48px;
    color: #c8af16;
    font-size: 24px;
    text-align: center;
}
  
.blogpost .caption {
    font-size: 14px;
    color: #999999;
    text-align: center;
    margin-top: -24px;
}
  
.blogpost .image {
    max-width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .4);
}

/* Navigate Buttons Styles */
.navigator-buttons button {
    height: 2em;
    line-height: 2em;
    text-align:center;
    font: 1.5em/100% "Lato", sans-serif;
    margin: 0.5em 0em;
    background-color: #DDDDDD;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
}

.navigator-buttons button:hover {
    background-color: #757575;
    color: white;
}

.navigator-buttons button:active {
    box-shadow: none;
}

.navigator-buttons .navigate-right-button {
    float: right;
}