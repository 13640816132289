/* General Styles */
body {
    padding-top: 79px; 
}
body,h2,h3,h4,h5,h6 {
	font-family: "Lato", sans-serif
}
h1,button {
	font-family: "Montserrat", sans-serif
}
a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

/* Remove ugly border after clicking link */
a:active, a:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
}

@media (max-width: 601px) {
    h1 {
        font-size: 2em!important;
    }
    h2 {
        font-size: 1.5em!important;
    }
    h3 {
        font-size: 1em!important;
    }
    h4 {
        font-size: 1em!important;
    }
    p {
        font-size: 1em!important;
    }
}